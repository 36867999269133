.receipt-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 400px;
    margin: auto;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Receipt Styles */
  .receipt {
    margin: 20px auto;
    padding: 10px;
    border: 1px solid #000;
    font-family: monospace;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    width: 300px; /* Optimized for receipt printers */
    box-sizing: border-box;
  }
  
  .receipt-header {
    text-align: center;
    font-weight: bold;
    margin: 5px 0;
    font-size: 14px;
  }
  
  .receipt-footer {
    text-align: center;
    font-size: 10px;
    margin: 5px 0;
  }
  
  .print-btn {
    display: block;
    margin: 10px auto 0;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  /* Ensure no grey background */
  body {
    background-color: white;
  }
  